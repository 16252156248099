import * as React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ArchiveLink from "../components/archiveLink"

const archiveTemplate = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.edges

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="archive" />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="archive" />
      <div className="main-heading">
        <h1>{pageContext.year}</h1>
      </div>
      <ArchiveLink />
      <ol style={{ listStyle: `none` }} className="post-list-archive">
        {posts.map(post => {
          const title = post.node.frontmatter.title || post.node.fields.slug

          return (
            <li key={post.node.fields.slug}>
              <article
                className="post-list-item"
                itemScope
              >
                <AniLink fade to={post.node.fields.slug} itemProp="url">
                  <header>
                    <h2>
                        <span itemProp="headline">{title}</span>
                    </h2>
                    <small>{post.node.frontmatter.date}</small>
                  </header>
                </AniLink>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default archiveTemplate

export const pageQuery = graphql`
  query($startDate: Date, $endDate: Date) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { date: { gte: $startDate, lt: $endDate }}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`
