import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"

const archivelLink = () => {
  const years = ["2021","2022","2023"]
  return (
    <div className="archive-year-link">
      <AniLink fade to="/blog">all</AniLink>
      {years.map(year => {
        const slug = "/archives/" + year
        return (
          <AniLink fade to={slug}>{year}</AniLink>
        )
      })}
    </div>
  )
}

export default archivelLink